import axios from "axios";

const ruta = 'equipos';
const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

export default {
  /**
   * Funcion que permite filtrar los equipos mostrados en la tabla de homeview
   * @param {object} parametrosBusqueda estado y tipo de equipo que desea buscar
   * @returns {object} los equipos filtrados
   */
  searchDevices(parametrosBusqueda){
    return apiClient.post(ruta + '/mostrar-equipos',parametrosBusqueda);
  },
  generarPdfEquipo(id) {
    return apiClient.post(ruta +"/generar-pdf-equipo",id);
},

  /**Funcion que llena los campos de los select */
  fillInputs() {
    return apiClient.post(ruta +"/llenar-campos");
  },
  /**Funcion que trae los datos de los campos select de las variables */
  datosFormVariables() {
      return apiClient.get(ruta +"/datos-form-variables");
  },
  /**
   * Funcion que ingresa a la api y pasa los datos para crear un nuevo equipo
   * 
   * @param {object} datosEquipo datos del equipo a crear
   * @returns {(number|false)} id del nuevo equipo y false si no se pudo crear
   */
  createDevice(datosEquipo) {
    return apiClient.post(ruta + "/crear", datosEquipo);
  },
  /**
   * Funcion que actualiza los datos de un equipo
   * 
   * @param {object} updatedDevice datos del equipo actualizado
   * @returns {(true|false)} si se pudo actualizar o false si no
   */
  editDevice(updatedDevice) {
    return apiClient.post(ruta + "/editar-equipo", updatedDevice);
  },
  /**
   * Funcion que ingresa a la api y crea una nueva marca
   * 
   * @param {string} nuevaMarca nombre de la nueva marca
   * @returns {(number|false)} id de la nueva marca o false si no se pudo crear
   */
  createBrand(nuevaMarca){
    return apiClient.put(ruta + "/crear-marca", nuevaMarca);
  },
  /**
   * Funcion que permite crear un nuevo proveedor
   * 
   * @param {object} newProvider datos del nuevo proveedor
   * @returns {(number|false)} id del nuevo proveedor o false si no se pudo crear
   */
  createNewProvider(newProvider) {
    return apiClient.put(ruta + "/crear-proveedor", newProvider);
  },
  /**
   * Funcion que agrega una variable a un equipo en  especifico
   * @param {object} variable id del equipo y la variable con sus rangos seleccionados
   * @returns {(object|false)} datos de la nueva variable agregada o false si no se pudo agregar
   */
  createVariable(variable) {
    return apiClient.put(ruta + "/crear-variable", variable);
  },
  /**
   * Funcion que permite actualizar los datos de una variable de un equipo en especifico
   * @param {object} varUpdated datos de la variable de un equipo modificados
   * @returns {(true|false)} si se pudo actualizar la variable o false si no
   */
  updateVariableDevice(varUpdated){
    return apiClient.put(ruta + "/actualizar-variable-equipo", varUpdated);
  },
  /**
   * Funcion que permite consultar los datos que pertenecen a un equipo en especifico
   * @param {object} idEquipo id del equipo a consultar los datos
   * @returns 
   */
  dataEquip(idEquipo){
    return apiClient.post(ruta + "/informacion-equipo", idEquipo);
  },
  /**
   * Funcion que ingresa a la api y crear una nueva variable
   * 
   * @param {Object} newVariable - La nueva variable
   * @returns {(number|false)} la respuesta de la api
   */
  createNewVariable(newVariable){
    return apiClient.put(ruta + "/crear-nueva-variable", newVariable);
  },

  /**
   * Funcion que ingresa a la api y pasa los parametros para crear un nuevo rango de medicion
   * 
   * @param {Object} NewMeasuringRange el nuevo rango de medicion y el id de la variable
   * @returns {(number|false)} el id del nuevo rango de medicion o false si no se pudo crear
   */
  createNewMedRange(NewMeasuringRange){
    return apiClient.put(ruta + "/crear-nuevo-rango-medicion", NewMeasuringRange);
  },
  
  /**
   * Funcion que ingresa a la api y pasa los parametros para crear un nuevo rango precision y exactitud 
   * 
   * @param {Object} NewPrecisionRange el nuevo rango precision y exactitud y el id de la variable
   * @returns {(number|false)} El id del nuevo rango precision y exactitud o false si no se pudo crear
   */
  createNewPrecisionRange(NewPrecisionRange){
    return apiClient.put(ruta + "/crear-nuevo-rango-precision", NewPrecisionRange);
  },
  /**
   * Funcion que ingresa a la api y pasa los parametros para crear un nuevo rango de division y escala
   * 
   * @param {Object} NewDivisionRange el nuevo rango division y escala y el id de la variable
   * @returns {(number|false)} la respuesta de la api
   */
  createNewDivisionRange(NewDivisionRange){
    return apiClient.put(ruta + "/crear-nuevo-rango-division", NewDivisionRange);
  },
  /**
   * Funcion que ingresa a la api y pasa los parametros para crear un nuevo rango de error maximmo permitido
   * 
   * @param {Object} NewErrorRange el nuevo rango de error maximmo permitido y el id de la variable
   * @returns {(number|false)} la respuesta de la api
   */
  createNewErrorRange(NewErrorRange){
    return apiClient.put(ruta + "/crear-nuevo-rango-error", NewErrorRange);
  },
  /**
   * Funcion que permite agregar un elemento adicional a un equipo en especifico
   * @param {object} aditionalDevice datos del nuevo equipo adicional
   * @returns {(number|string)} id del elemento adicional almacenado o el error de base de datos
   */
  createAditionalDevice(aditionalDevice) {
    return apiClient.put(ruta + "/crear-equipo-adicional", aditionalDevice);
  },
  /**
   * Funcion que permite actualizar los datos de un elemento adicional de un equipo
   * @param {object} updatedAdDevice datos modificados de un elemento adicional de un equipo
   * @returns {(true|false)} si se pudo actualizar el elemento adicional o false si no
   */
  updateAditionalDevice(updatedAdDevice){
    return apiClient.put(ruta + "/editar-equipo-adicional", updatedAdDevice);
  },
  /**
   * Funcion que permite agregar un proveedor y unos datos de compra a un equipo y finaliza la creacion de equipo
   * @param {object} data datos tanto del proveedor seleccionado como los datos de compra de un equipo
   * @returns {(object|false)} datos del equipo creado o false si hubo un error
   */
  finishFormDevice(data) {
    return apiClient.post(ruta + "/finalizar-form-equipo",data);
  },
  /**
   * Funcion que permite actualizar los datos del proveedor y de compra de un equipo
   * @param {object} data datos modificados tanto del proveedor seleccionado como los datos de compra de un equipo
   * @returns {(true|false)} si los datos fueron actualizados o false si hubo un error
   */
  finishEdit(data){
    return apiClient.post(ruta + "/finalizar-form-editar",data);
  },
  // getFileContent(data){
  //   return apiClient.post("/get-file-content",data);
  // },
  /**
   * Funcion que permite eliminar un equipo creado
   * @param {number} id id del equipo a eliminar
   * @returns {(true|false)} si se pudo eliminar o false si no
   */
  deleteEquip(id) {
    return apiClient.delete(ruta + "/borrar",{params :{id:id}});
  },
  /**
   * Funcion que permite cambiar el estado de un equipo
   * @param {object} status id del equipo y el estado actual de este
   * @returns {(number|false)} el estado actualizado del equipo
   */
  desactiveEquip(status){
    return apiClient.put(ruta + "/desactivar-equipo",status);
  },
  api(){
    return apiClient
  }
};

