import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store'

Vue.use(VueRouter)

const routes = [

  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue')
  },
  {
    path: '/consultar-pqrs',
    name: 'consultar-pqrs',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ConsultrPqrs.vue')
  },
  {
    path: '/reportar-pqrs',
    name: 'reportar-pqrs',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReportarPqrs.vue')
  },
  {
    path: '/registrar-usuarios',
    name: 'registrar-usuarios',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/RegistrarClientes.vue')
  },
  {
    path: '/consultar-usuario',
    name: 'consultar-usuario',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ConsultarUsuario.vue')
  },
  {
    path: '/rtm-dia',
    name: 'rtm-dia',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/RtmDia.vue')
  },
  {
    path: '/encuesta',
    name: 'Encuesta',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Encuesta.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/',
    name: 'home',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/registrar-mantenimiento/:id',
    name: 'registrar-mantenimiento',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/RegistrarMantenimiento.vue')
  },
  {
    path: '/registrar-actividad',
    name: 'registrar-actividad',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/RegistrarActividad.vue')
  },
  {
    path: '/mantenimiento',
    name: 'mantenimiento',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Mantenimiento.vue')
  },
  {
    path: '/registrar-usuario',
    name: 'registrar-usuario',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/RegistrarClientes.vue')
  },
  {
    path: '/**',
    name: '404',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },

]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (store.state.token != null) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router
