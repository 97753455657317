import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { getField, updateField } from "vuex-map-fields";
import services from "@/services";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {
    stateLoad: false,
    device_id: '',

    provider: {
      nombreProveedor:"",
      idProveedor: "",
      telefonoProveedor: "",
      celularProveedor: "",
      direccionProveedor: "",
      emailProveedor:"",
    },

    pusharse: {
      date_compra:'',
      date_instalacion: '',
      date_garantia: '',
      aditionalFile:[]
    },
  
    variablesShow:[],
    equiposAdShow:[],
    ubicaciones: [],
    marcas: [],
    tiposE: [],
    file_codes:[],
    versions:[],
    proveedores: [],
    variablesE: [],
    token: null,
    permisos:[],
    roles_hermes:[],
  },
  getters: {
    getField,
  },
  mutations: {
    changeLoad(state){
      state.stateLoad = !state.stateLoad
    },
    setToken(state,token){
      state.token = token
    },
    setPermisos(state,permisos){
      state.permisos = permisos;
    },
    setRoles(state,roles){
      state.roles_hermes = roles;
    },
    updateField,

    /**
     * Funcion que agrega una nueva marca a la lista de marcas en el state
     * 
     * @param {object} state componentes del state
     * @param {object} marca el id y el nombre de la nueva marca
     * @returns 
     */
    agregarMarca(state,marca){
      state.marcas.push(marca);
    },

    /**
     * Funcion que actualiza el valor del id del equipo en el state
     * 
     * @param {object} state componentes del state
     * @param {number} id id del nuevo equipo creado
     * @returns {void}
     */
    updateIdDevice(state, id) {
      state.device_id = id;
    },

    insertVariable(state,variable){
      state.variablesShow.push(variable)
    },

    /**
     * Funcion que agrega un nuevo proveedor a la lista de proveedores
     * 
     * @param {*} state componentes del state
     * @param {object} provider datos del nuevo proveedor
     * @returns {void}
     */
    insertProvider(state,provider){
      state.proveedores.push(provider)
    },

    insertEquipAd(state,equipo){
      state.equiposAdShow.push(equipo)
    },

    llenarDatosForm(state, datos) {
      state.proveedores = datos["providers"];
      state.file_codes = datos['file_codes'];
      state.versions = datos['versions'];
      state.tiposE = datos["types"];
      state.ubicaciones = datos["ubicaciones"];
      state.marcas = datos["marcas"];
     
    },
  },
  actions: {
    /** Funcion que obtiene la mayoria de datos de los select del formulario */
    ObtenerCamposForm() {
      services.fillInputs().then((response) => {
        this.commit("llenarDatosForm", response.data);
      });
    },
    
  },
  modules: {},
});
